.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.pre-potts .list-group-item.active {
  background-color: #34548c;
  border-color: #fff;
}
.form-control{
  margin-bottom: 15px;
}
.tab-pane.active{
  padding: 1rem;
  background-color: #eee;
  border-radius: 8px;
  margin-bottom: 15px;
}
.selectedProduct{
  background-color: #ddd;
  padding: 5px 10px;
  border-radius: 5px;
  margin-bottom: 5px;
}
.loadingdiv{
  margin: 10px auto;
  text-align: center;
}

.syncContainer{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  text-align: right;
}
.syncButton{
  position: sticky;
  top: 60px;
}
.trash-icon{
  height: 20px;
  transition: transform .2s;
  cursor: pointer;
}
.trash-icon:hover{
  height: 20px;
  transform: scale(1.3);
}
.selectedList{
  max-height: 590px;
  overflow-y: scroll;
}